<template>
  <div>
    <v-row>
      <v-col v-for="item in listaParametrosOperatoriaCliente" cols="12" :key="item.id">
        <v-text-field
          v-if="item.clave !== 'cbu_enlace' && item.clave !== 'cuil_enlace'"
          v-model="item.model"
          :label="item.nombre"
          :rules="regla"
          :type="validarTipo(item.options)"
          required
        ></v-text-field>
        <v-text-field
          v-if="item.clave === 'cuil_enlace'"
          v-model="item.model"
          :label="item.nombre"
          :rules="regla"
          :type="validarTipo(item.options)"
          @blur="checkEnlaceMensual(item.model)"
          required
        ></v-text-field>
        <v-text-field
          v-if="item.clave === 'cbu_enlace'"
          v-model="item.model"
          ref="cbutextfield"
          :label="item.nombre"
          :success="cbuSuccess"
          :loading="loadingCBU"
          :error="cbuError"
          :messages="cbuMsg"
          @change="validarCBU(item.model)"
          :hide-details="false"
          type="text"
          required
          class="notranslate"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-card v-if="listaParametrosOperatoriaCliente.length < 1">
          <v-card-title>Datos Requisitos: </v-card-title>
          <v-card-text>No se encuentra habilitado por falta de parámetros </v-card-text>
        </v-card>
        <div>
          <v-checkbox
            v-if="listaParametrosOperatoriaCliente.length !== 0 && !datosConfirmados"
            v-model="checkbox"
            :rules="[(v) => !!v || 'Debe aceptar para continuar!']"
            label="Acepto los valores"
            required
          ></v-checkbox>
          <v-row>
            <v-col>
              <v-btn
                v-if="listaParametrosOperatoriaCliente.length !== 0 && !datosConfirmados"
                color="success"
                class="mr-4"
                @click="setDatosParametros"
                :disabled="listaParametrosOperatoriaCliente.some((el) => el.model === '') || !checkbox"
              >
                Confirmar Valores
              </v-btn>
              <v-alert v-if="datosConfirmados" border="left" color="green" dense outlined text type="success"
                >Los parametros han sido confirmados</v-alert
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-alert v-if="success" border="bottom" color="success" dark>
          {{ successMensaje }}
        </v-alert>
        <v-alert v-if="error" border="bottom" color="red" dark>
          <b>Ocurrió un error al guardar el Parametro de la operatoria.</b> {{ errorMensaje }}
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';
import axios from 'axios';

export default {
  props: ['listaParametrosOperatoriaCliente', 'parametrosOperatoria', 'lineaSeleccionada'],
  data: () => ({
    cbuMsg: '',
    cbuError: false,
    cbuSuccess: false,
    loadingCBU: false,
    datosParametros: [],
    datosConfirmados: false,
    valid: true,
    localidades: [],
    tipoDeDocumentos: [],
    codigoIva: [],
    token: localStorage.getItem('token'),
    rules: {
      required: (value) => !!value || 'Campo requerido',
      number: (value) => /[0-9]*[.][0-9]+/.test(value) || 'El valor ingresado debe ser numérico.-',
    },
    rulesTopeReintegro: [],
    checkbox: false,
    success: false,
    error: false,
    successMensaje: '',
    errorMensaje: '',
  }),
  methods: {
    validarTipo(t) {
      let r = '';
      if (t === 'int') {
        r = 'number';
      } else {
        r = 'string';
      }
      return r;
    },
    setDatosParametros() {
      this.datosParametros = this.listaParametrosOperatoriaCliente.map((el) => ({
        id: el.id,
        nombre: el.clave,
        value: el.model,
      }));
      this.$emit('enviarParametros', this.datosParametros);
      this.datosConfirmados = true;
    },
    validarCBU(clavebancariaunica) {
      this.loadingCBU = true;
      const url = `${API_URL}api/validar/cbu`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      const data = {
        cbu: clavebancariaunica,
      };
      axios.post(url, data, header).then((result) => {
        let msg = '';
        if (result.data.valid) {
          msg = 'CBU válido';
          this.cbuSuccess = true;
          this.cbuError = false;
        } else {
          msg = 'CBU no válido';
          this.cbuError = true;
          this.cbuSuccess = false;
        }
        this.loadingCBU = false;
        this.cbuMsg = msg;
      });
    },
    checkEnlaceMensual() {
      const cuil = this.listaParametrosOperatoriaCliente.find((e) => e.clave === 'cuil_enlace').model;
      console.log(cuil);
      axios
        .post(`${API_URL}api/enlace/validarcuit`, {
          token: this.token,
          cuil,
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === 'success') {
            this.$swal
              .fire({
                title: 'Máximo permitido un legajo por mes',
                confirmButtonText: 'Aceptar',
              })
              .then(async (result) => {
                if (result.isConfirmed) {
                  this.listaParametrosOperatoriaCliente.find((e) => e.clave === 'cuil_enlace').model = '';
                }
              });
          }
        });
    },
  },
  computed: {
    regla() {
      return [(v) => !!v || 'Falta completar campo'];
    },
  },
};
</script>
<style>
.swal2-title,
.swal2-content,
.swal2-styled {
  font-family: Roboto, sans-serif !important;
}
</style>
